import React, { useCallback, useEffect, useState } from "react";
import { View, Card, Text, Button, Image } from "react-native-ui-lib";
import { Col, CustomNumberInput, CustomSelect, CustomTextField, CustomUpload, DangerButton, Label, PrimaryButton, Row, SecondaryButton, SelectField } from "../../mycomponents/DynamicForm";
import { DeviceEventEmitter, Platform } from "react-native";
import { addButton } from "../hrms/shiftModel";
import { api } from "../../services/api";
import dayjs from 'dayjs';
import ReportScreenSingle from "../../screens/MainScreens/CRUD/ReportScreenSingle";
import { App } from 'antd';
import moment from "moment";
import ListingScreen from "../../screens/MainScreens/CRUD/ListingScreen";
import { TransactionPDFPreview } from "./transaction";

export const contactfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData, message, navigationFn, setOpenModal) => {

    const AddressesComponent = ({ field, useForm }) => {
        const addresses = useForm((state) => state.formObject["addresses"]) || [];

        const setFormObject = useForm((state) => state.setFormObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const getFormObject = useForm((state) => state.getFormObject);

        const [states, setStates] = useState({});

        useEffect(() => {
            for (let i = 0; i < addresses.length; i++) {
                const address = addresses[i];

                if (address.country && !states[i]) {
                    (async () => {
                        try {
                            const response = await api.customRoute('Contacts/action', {
                                action: 'getStates',
                                country_id: address.country
                            });

                            const newStates = response;
                            setStates({ ...states, [i]: newStates });
                        } catch (e) {

                        }
                    })();
                }
            }
        }, [addresses]);

        return <>
            <View flex={Platform.OS == 'web'}>
                {addresses && addresses.map((obj, index) => {
                    return <Row key={index} style={{ marginTop: 10, alignItems: 'center' }} >
                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }} row={Platform.OS != 'web'}>
                                {Label({ label: 'Country' })}
                                {!view ? <CustomSelect independent value={obj.country} options={response.Countries || []} onChange={async (value) => {
                                    setFormObjectArray('addresses', index, 'country', value);

                                    try {
                                        const response = await api.customRoute('Contacts/action', {
                                            action: 'getStates',
                                            country_id: value
                                        });

                                        const newStates = response;
                                        setStates({ ...states, [index]: newStates });
                                    } catch (e) {

                                    }
                                }} /> : <Text>{response.Countries.find((country) => country.value == obj.country)?.label}</Text>}
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'State' })}
                                {!view ? <CustomSelect independent value={obj.state} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'state', value);
                                }} options={states[index] || []} /> : <Text>{states[index]?.find((state) => state.value == obj.state)?.label}</Text>}
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Pincode' })}
                                {!view ? <CustomTextField independent value={addresses[index].pincode} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'pincode', value);
                                }} /> : <Text>{obj.pincode}</Text>}
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'City' })}
                                {!view ? <CustomTextField independent value={obj.city} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'city', value);
                                }} /> : <Text>{obj.city}</Text>}
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Address Line 1' })}
                                {!view ? <CustomTextField independent value={obj.address_line_1} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'address_line_1', value);
                                }} /> : <Text>{obj.address_line_1}</Text>}
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Address Line 2' })}
                                {!view ? <CustomTextField independent value={obj.address_line_2} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'address_line_2', value);
                                }} /> : <Text>{obj.address_line_2}</Text>}
                            </View>
                        </Col>
                    </Row>
                })}
            </View>
        </>
    }

    const LensDetailsComponent = ({ field, useForm }) => {
        const lens_details = useForm((state) => state.formObject["lens_details"]);
        const setFormObject = useForm((state) => state.setFormObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const getFormObject = useForm((state) => state.getFormObject);
        const [states, setStates] = useState({});

        return <>
            <View flex={Platform.OS == 'web'}>
                {lens_details && [...(lens_details?.reverse() || [])].map((obj, index) => {
                    return <Row key={index} style={{ marginTop: 10, alignItems: 'center' }} >
                        <Col width="100%">
                            <View row centerV spread style={{
                                width: '100%'
                            }}>
                                <Text tabHeading>Eye Details - {obj['date'] ? dayjs(obj['date']).format('DD MMM YYYY hh:mm A') : ''}</Text>
                                <DangerButton
                                    style={{
                                        marginTop: 8
                                    }}
                                    label="Delete" onPress={() => {
                                        const newLensDetails = [...lens_details];
                                        newLensDetails.splice(index, 1);
                                        setFormObject({
                                            'lens_details': newLensDetails
                                        });
                                    }} />
                            </View>
                        </Col>

                        <Col width="100%">
                            <View center style={{
                                width: '100%',
                            }}>
                                <Text text90 style={{ fontWeight: 'bold' }}>Right Eye</Text>
                            </View>
                            <View style={{
                                borderBottomColor: '#d9d9d9',
                                borderBottomWidth: 1,
                                width: '100%',
                                marginBottom: 0,
                                marginTop: 10,
                                borderStyle: 'dashed',
                            }} />
                        </Col>



                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: '' })}
                                <View marginT-40>
                                    {Label({ label: 'Distant' })}
                                </View>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Sph' })}
                                <CustomTextField placeholder={""} independent value={obj.distant_re_sph} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_re_sph', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Cyl' })}
                                <CustomTextField independent value={obj.distant_re_cyl} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_re_cyl', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Axis' })}
                                <CustomTextField independent value={obj.distant_re_axis} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_re_axis', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'VA' })}
                                <CustomTextField independent value={obj.distant_re_va} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_re_va', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: '' })}
                                <View marginT-40>
                                    {Label({ label: 'Add' })}
                                </View>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Sph' })}
                                <CustomTextField independent value={obj.add_re_sph} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_re_sph', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Cyl' })}
                                <CustomTextField independent value={obj.add_re_cyl} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_re_cyl', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Axis' })}
                                <CustomTextField independent value={obj.add_re_axis} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_re_axis', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'VA' })}
                                <CustomTextField independent value={obj.add_re_va} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_re_va', value);
                                }} />
                            </View>
                        </Col>


                        <Col width="100%">
                            <View center style={{
                                width: '100%',
                            }}>
                                <Text text90 style={{ fontWeight: 'bold' }}>Left Eye</Text>
                            </View>
                            <View style={{
                                borderBottomColor: '#d9d9d9',
                                borderBottomWidth: 1,
                                width: '100%',
                                marginBottom: 0,
                                marginTop: 10,
                                borderStyle: 'dashed',
                            }} />
                        </Col>


                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: '' })}
                                <View marginT-40>
                                    {Label({ label: 'Distant' })}
                                </View>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Sph' })}
                                <CustomTextField independent value={obj.distant_le_sph} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_le_sph', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Cyl' })}
                                <CustomTextField independent value={obj.distant_le_cyl} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_le_cyl', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Axis' })}
                                <CustomTextField independent value={obj.distant_le_axis} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_le_axis', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'VA' })}
                                <CustomTextField independent value={obj.distant_le_va} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_le_va', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: '' })}
                                <View marginT-40>
                                    {Label({ label: 'Add' })}
                                </View>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Sph' })}
                                <CustomTextField independent value={obj.add_le_sph} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_le_sph', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Cyl' })}
                                <CustomTextField independent value={obj.add_le_cyl} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_le_cyl', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Axis' })}
                                <CustomTextField independent value={obj.add_le_axis} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_le_axis', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'VA' })}
                                <CustomTextField independent value={obj.add_le_va} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_le_va', value);
                                }} />
                            </View>
                        </Col>

                    </Row>
                })}
            </View>
        </>
    }

    const LedgerAccountComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const account_id = useForm((state) => state.formObject['account_id']);

        return <View flex>
            <ReportScreenSingle
                moduleName={"Ledger"}
                hideWrapper={true}
                noMono={true}
                hideHeader={true}
                params={{
                    quickFilterKey: 'account_id',
                    quickFilterValue: account_id,
                    hideQuickFilter: true,
                }}
                route={{
                    params: {
                        quickFilterKey: 'account_id',
                        quickFilterValue: account_id,
                        hideQuickFilter: true,
                        name: "Ledger",
                    }
                }}
            />
        </View>
    });

    const ExtraGSTNumbers = ({ field, useForm }) => {
        const gst_numbers = useForm((state) => state.formObject['gst_numbers']);

        const setFormObject = useForm((state) => state.setFormObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const getFormObject = useForm((state) => state.getFormObject);

        return <>

            <View flex>
                {gst_numbers && gst_numbers.map((obj, index) => {
                    return <Row key={index} style={{ marginTop: 10, alignItems: 'center' }} >
                        <Col width="100%">
                            <Text tabHeading>GST Number {index + 1}</Text>
                        </Col>

                        <Col width="50%">
                            <CustomTextField label="GST Numbers" value={obj.gst_number} onChange={(value) => {
                                setFormObjectArray('gst_numbers', index, 'gst_number', value);
                            }} />
                        </Col>

                        <Col width="50%">
                            <CustomSelect label="Primary" value={obj.primary} options={[
                                { label: 'Yes', value: "Yes" },
                                { label: 'No', value: "No" },
                            ]} onChange={(value) => {
                                setFormObjectArray('gst_numbers', index, 'primary', value);
                                const newArray = [...getFormObject().gst_numbers];
                                let otherDefault = false;
                                newArray.forEach((data, i) => {
                                    if (i != index && value == "Yes") {
                                        data.primary = "No";
                                    }
                                    if (i != index && value == "No") {
                                        if (!otherDefault) {
                                            data.primary = "Yes";
                                            otherDefault = true;
                                        }
                                    }
                                });
                                setFormObject({ ...getFormObject(), gst_numbers: newArray });
                            }} />
                        </Col>

                        <View style={{ marginTop: 18 }}>
                            <Button outline outlineColor={'red'} label="Delete" onPress={() => {
                                const newObject = { ...getFormObject() };
                                const newGstNumbers = [...newObject.gst_numbers];
                                newGstNumbers.splice(index, 1);
                                newObject.gst_numbers = newGstNumbers;
                                setFormObject(newObject);
                            }} />
                        </View>
                    </Row>
                })}
            </View>
        </>
    }

    const SubscriptionComponent = ({ field, useForm, tKey, tIndex }) => {
        const [subscriptions, setSubscriptions] = React.useState([]);
        const contact_id = useForm((state) => state.formObject['_id']);

        const { message, modal } = App.useApp();

        const getSubscriptions = async () => {
            const responseData = await api.get("Subscriptions", {
                page: 1,
                limit: 1000,
                filters: [],
                tabFilter: { contact_id: contact_id },
                search: "",
                sortColumn: 'end_date',
                sortOrder: 'desc',
                groupBy: null
            });

            console.log("responseData", responseData);

            setSubscriptions(responseData.data.data);
        };

        useEffect(() => {
            getSubscriptions();
        }, []);

        return <>
            <View flex row style={{
                flexWrap: 'wrap'
            }}>
                {subscriptions && subscriptions.length > 0 && subscriptions.map((subscription, i) => (<View key={i} style={{
                    flexBasis: '33.33%'
                }}>
                    <Card margin-10 padding-10 flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
                        <View marginB-10 row spread style={{ width: '100%' }}>

                            <View flex>
                                <View row spread>
                                    <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                                        {subscription.item.name}
                                    </Text>
                                    {moment(subscription.end_date).isBefore(moment()) && <View style={{
                                        backgroundColor: '#cb1721',
                                        paddingHorizontal: 10,
                                        paddingVertical: 5,
                                        borderRadius: 5,
                                    }}>
                                        <Text style={{ color: 'white' }}>Expired</Text>
                                    </View>}
                                </View>


                                {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                                    <View
                                        style={{
                                            borderStyle: 'dashed',
                                            borderWidth: 1,
                                            borderColor: '#d9d9d9',
                                            margin: -2,
                                            marginTop: 5,
                                        }}>
                                        <View style={{ height: 20, width: '100%' }} />
                                    </View>
                                </View> : <View style={{
                                    borderBottomColor: '#d9d9d9',
                                    borderBottomWidth: 1,
                                    width: '100%',
                                    marginBottom: 0,
                                    marginTop: 5,
                                    borderStyle: 'dashed',
                                }} />}

                                <View flex row marginT-10>
                                    <View row marginB-6 flex centerV>
                                        <View>
                                            <Text lightCardTitle>{"Start Date"}: </Text>
                                        </View>
                                        <View flex>
                                            <Text>{moment(subscription.start_date).format('DD MMM YYYY')}</Text>
                                        </View>
                                    </View>
                                    <View row marginB-6 flex>
                                        <View>
                                            <Text lightCardTitle>{"Expiry Date"}: </Text>
                                        </View>
                                        <View flex>
                                            <Text>{moment(subscription.end_date).format('DD MMM YYYY')}</Text>
                                        </View>
                                    </View>
                                </View>

                                {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                                    <View
                                        style={{
                                            borderStyle: 'dashed',
                                            borderWidth: 1,
                                            borderColor: '#d9d9d9',
                                            margin: -2,
                                            marginTop: 5,
                                        }}>
                                        <View style={{ height: 20, width: '100%' }} />
                                    </View>
                                </View> : <View style={{
                                    borderBottomColor: '#d9d9d9',
                                    borderBottomWidth: 1,
                                    width: '100%',
                                    marginBottom: 0,
                                    marginTop: 5,
                                    borderStyle: 'dashed',
                                }} />}

                                {subscription.subscription_item_usage.map((item, j) => (<View key={j} marginT-10>
                                    <View flex>
                                        <View row marginB-6 flex spread centerV>
                                            <View>
                                                <Text lightCardTitle>
                                                    {item.item?.name}
                                                </Text>
                                            </View>
                                            <View flexG row centerV right>
                                                <View marginR-5 style={{
                                                    width: 80,
                                                }}>
                                                    <CustomNumberInput
                                                        type="number"
                                                        placeholder="PRP"
                                                        visible={true}
                                                        width="100%"
                                                        value={item.used_quantity}
                                                        onChange={(value) => {
                                                            const newSubscriptions = [...subscriptions];
                                                            const newSubscription = { ...newSubscriptions[i] };
                                                            const newSubscriptionItemUsage = [...newSubscription.subscription_item_usage];
                                                            const newSubscriptionItemUsageItem = { ...newSubscriptionItemUsage[j] };
                                                            newSubscriptionItemUsageItem.used_quantity = value;
                                                            newSubscriptionItemUsage[j] = newSubscriptionItemUsageItem;
                                                            newSubscription.subscription_item_usage = newSubscriptionItemUsage;
                                                            newSubscriptions[i] = newSubscription;
                                                            setSubscriptions(newSubscriptions);
                                                        }}
                                                    />
                                                </View>
                                                <Text>used out of </Text> <Text style={{ fontFamily: 'SourceSansProBold' }}>{item.quantity}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>))}

                                <View marginT-10>
                                    <PrimaryButton
                                        label="Update"
                                        onPress={async () => {
                                            modal.confirm({
                                                title: 'Are you sure you want to update the usage?',
                                                content: 'This action cannot be undone.',
                                                okText: 'Yes',
                                                cancelText: 'No',
                                                onOk: async () => {
                                                    const response = await api.customRoute('Invoices/action', {
                                                        action: 'updateSubscriptionUsage',
                                                        _id: subscription.invoice_id,
                                                        newQuantityArray: subscriptions[i].subscription_item_usage
                                                    });

                                                    if (response.success) {
                                                        message.success('Updated successfully');
                                                    } else {
                                                        message.error('Error updating');
                                                    }
                                                },
                                                onCancel: () => {
                                                }
                                            });
                                        }}
                                    />
                                </View>
                            </View>
                        </View>

                    </Card>
                </View>))}
            </View>
        </>
    };

    const AppointmentComponent = ({ field, useForm, tKey, tIndex }) => {
        const contact_id = useForm((state) => state.formObject['_id']);

        return <View flex>
            <ListingScreen
                {...{
                    moduleName: 'Appointments',
                    hideWrapper: true,
                    extraFilters: {
                        contact_id: contact_id
                    },
                    hideViews: true,
                    hideMainHeader: true,
                    removeOtherFilters: true,
                }}
                title={" "}
                disabledClone={true}
                disableDelete={true}
            />
        </View>
    }

    const MedicalHistoryComponent = ({ field, useForm, tKey, tIndex }) => {
        const contact_id = useForm((state) => state.formObject['_id']);
        const [medicalHistory, setMedicalHistory] = useState([]);
        const uploaded_documents = useForm((state) => state.formObject['cf___uploaded_documents']) || [];
        const setFormObject = useForm((state) => state.setFormObject);
        const obj = useForm((state) => state.formObject);
        const [loaded, setLoaded] = useState(false);

        const getData = async () => {
            const responseData = await api.get("Appointments", {
                page: 1,
                limit: 10,
                sortColumn: 'date',
                sortDirection: 'desc',
                filters: [],
                tabFilter: {
                    contact_id: contact_id,
                    status: "Completed"
                },
                search: "",
                groupBy: null
            });

            setMedicalHistory(responseData.data.data);
        }
        console.log("uploaded_documents", uploaded_documents);

        useEffect(() => {
            getData();

            if (obj['cf'] && obj['cf']['uploaded_documents']) {
                setFormObject({
                    'cf___uploaded_documents': obj['cf']['uploaded_documents']
                });
            }

            setLoaded(true);
        }, []);


        useEffect(() => {
            if (loaded) {
                const newObj = { ...obj };
                if (newObj['cf']) {
                    newObj['cf']['uploaded_documents'] = uploaded_documents;
                } else {
                    newObj['cf'] = {
                        uploaded_documents: uploaded_documents
                    }
                }
                api.update("Contacts", newObj, obj._id).then((res) => {

                });
            }
        }, [uploaded_documents, loaded]);

        return <View flex row>
            <View row style={{
                flexWrap: 'wrap',
                flexBasis: '50%'
            }}>
                {medicalHistory && medicalHistory.length > 0 && medicalHistory.map((history, i) => (<View key={i} style={{
                    flexBasis: '33.33%'
                }}>
                    <Card margin-10 padding-10 flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
                        <View marginB-10 row spread style={{ width: '100%' }}>

                            <View flex>
                                <View row spread>
                                    <Text style={{ marginBottom: 5, fontSize: 13, fontFamily: 'SourceSansProBold' }}>
                                        Date:
                                    </Text>
                                    <Text right style={{ marginBottom: 5, fontSize: 13 }}>
                                        {moment(history.date).format('DD MMM YYYY hh:mm A')}
                                    </Text>
                                </View>
                                <View row spread>
                                    <Text style={{ marginBottom: 5, fontSize: 13, fontFamily: 'SourceSansProBold' }}>
                                        Symptoms:
                                    </Text>
                                    <Text right style={{ marginBottom: 5, fontSize: 13 }}>
                                        {history.symptoms.length > 0 ? history.symptoms.map((symptom) => symptom.name).join(', ') : '-'}
                                    </Text>
                                </View>
                                <View row spread>
                                    <Text style={{ marginBottom: 5, fontSize: 13, fontFamily: 'SourceSansProBold' }}>
                                        Diagnosis:
                                    </Text>
                                    <Text right style={{ marginBottom: 5, fontSize: 13 }}>
                                        {history.diagnosis.length > 0 ? history.diagnosis.map((diagnosis) => diagnosis.name).join(', ') : '-'}
                                    </Text>
                                </View>
                                <View row spread>
                                    <Text style={{ marginBottom: 5, fontSize: 13, fontFamily: 'SourceSansProBold' }}>
                                        Medication:
                                    </Text>
                                    <Text right style={{ marginBottom: 5, fontSize: 13 }}>
                                        {history.medications.length > 0 ? history.medications.map((medication) => medication.medicine).join(', ') : '-'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </Card>
                </View>))}
            </View>
            <View style={{
                flexWrap: 'wrap',
                flexBasis: '25%'
            }}>
                {Label({ label: 'Old Documents' })}
                <CustomUpload
                    moduleName="Contacts"
                    multiple={true}
                    value={uploaded_documents}
                    mimeType="*/*"
                    onChange={(value) => {
                        // setFormObject('cf___uploaded_documents', value);
                        console.log("uploaded_documents1", value);
                        setFormObject({
                            'cf___uploaded_documents': value
                        });
                    }}
                    showNaming={true}
                />
            </View>
        </View>
    }

    const VisitsComponent = ({ field, useForm, tKey, tIndex }) => {
        const contact_id = useForm((state) => state.formObject['_id']);
        const [medicalHistory, setMedicalHistory] = useState([]);
        const [selectedAppointment, setSelectedAppointment] = useState(null);

        const getData = async () => {
            const responseData = await api.get("Appointments", {
                page: 1,
                limit: 10,
                sortColumn: 'date',
                sortDirection: 'desc',
                filters: [],
                tabFilter: {
                    contact_id: contact_id,
                    status: "Completed"
                },
                search: "",
                groupBy: null
            });

            setMedicalHistory(responseData.data.data);

            if (responseData.data.data.length > 0) {
                setSelectedAppointment(responseData.data.data[0]);

                if (!responseData.data.data[0].pdf_url) {
                    const interval = setInterval(async () => {
                        const response = await api.get("Appointments", {
                            page: 1,
                            limit: 10,
                            sortColumn: 'date',
                            sortDirection: 'desc',
                            filters: [],
                            tabFilter: {
                                contact_id: contact_id,
                                status: "Completed"
                            },
                            search: "",
                            groupBy: null
                        });

                        if (response.data.data[0].pdf_url) {
                            setSelectedAppointment(response.data.data[0]);
                            clearInterval(interval);
                        }
                    }, 5000);
                }
            }
        }

        useEffect(() => {
            getData();

            DeviceEventEmitter.addListener('reloadAppointments', () => {
                alert('reloading');
                getData();
            });

            return () => {
                DeviceEventEmitter.removeAllListeners('reloadAppointments');
            }
        }, []);

        return <View flex row>
            <View flex style={{
                width: '30%',
                paddingRight: 20,
                borderRightWidth: 2,
                borderRightColor: '#d9d9d9',
                marginRight: 40
            }}>
                {medicalHistory && medicalHistory.length > 0 && medicalHistory.map((history, i) => (<View key={i}>
                    <Card enableShadow={false} backgroundColor="#d3ebff" margin-10 padding-10 style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 4 }} onPress={() => {
                        setSelectedAppointment(history);
                    }}>
                        <View row spread style={{ width: '100%' }}>
                            <View flex>
                                <View row spread>
                                    <Text style={{ marginBottom: 5, fontSize: 13, fontFamily: 'SourceSansProBold' }}>
                                        {moment(history.date).format('DD MMM YYYY hh:mm A')} {"\n"}
                                        {/* Show week day as well */}
                                        {moment(history.date).format('dddd')}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </Card>
                </View>))}
            </View>
            <View style={{
                width: '70%'
            }}>
                {selectedAppointment ? <View key={selectedAppointment._id}>
                    <View right marginB-10>
                        <SecondaryButton label={"EDIT PRESCRIPTION"} onPress={() => {
                            if (Platform.OS === 'web') {
                                setOpenModal({
                                    ["Appointments"]: {
                                        isVisible: true,
                                        moduleName: "Appointments",
                                        id: selectedAppointment._id,
                                        viewOnly: false,
                                        isEdit: true,
                                        afterSaveEvent: 'reloadAppointments'
                                    }
                                })
                            } else {
                                navigationFn('New', {
                                    moduleName: "Appointments",
                                    isEdit: true,
                                    viewOnly: false,
                                    id: selectedAppointment._id,
                                    goBackAfterSave: true
                                });
                            }
                        }} />

                    </View>
                    {selectedAppointment.pdf_url ? <TransactionPDFPreview moduleName="Appointments" obj={selectedAppointment} /> : <Text>Prescription is getting generated.</Text>}
                </View> : null}
            </View>
        </View>
    }

    let formFields = [
        {
            type: 'divider',
            heading: 'Contact Details',
            visible: true,
            span: 24,
            tab: 'General',
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the Contact Name',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        // {
        //     label: 'Company Name',
        //     key: 'company_name',
        //     type: 'text',
        //     placeholder: 'Enter the Company Name',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General'
        // },
        // {
        //     label: 'Website',
        //     key: 'website',
        //     type: 'text',
        //     placeholder: 'Enter the Website',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General'
        // },
        // {
        //     label: 'Status',
        //     key: 'status',
        //     type: 'select',
        //     placeholder: 'Select the Status',
        //     visible: true,
        //     width: '25%',
        //     options: [
        //         { label: 'Active', value: 'active' },
        //         { label: 'Inactive', value: 'inactive' },
        //     ],
        //     tab: 'General'
        // },
        {
            label: 'Email',
            key: 'email',
            type: 'text',
            placeholder: 'Enter the Email',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Phone',
            key: 'phone',
            type: 'text',
            placeholder: 'Enter the Phone Number',
            visible: true,
            width: '25%',
            tab: 'General',
            showCountryCode: true
        },
        // {
        //     label: 'Contact Category',
        //     key: 'contact_category_id',
        //     type: 'select',
        //     placeholder: 'Select the Contact Category',
        //     visible: true,
        //     width: '25%',
        //     options: response.ContactCategories || [],
        //     tab: 'General',
        //     creatableAction: (props) => {
        //         const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

        //         OpenModalCustom({ newModule: "Categories", field_key: 'contact_category_id', params })
        //     },
        // },
        {
            label: 'Balance',
            key: 'balance',
            type: 'number',
            placeholder: 'Enter the Balance',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        // {
        //     label: 'Opening Balance',
        //     key: 'opening_balance',
        //     type: 'number',
        //     placeholder: 'Enter the Opening Balance',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General',
        //     value: 0
        // },

        {
            label: 'Date of Birth',
            key: 'date_of_birth',
            type: 'date',
            placeholder: 'Enter Date of Birth',
            visible: true,
            width: '25%',
            moduleName: moduleName,
            tab: 'General',
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                // update age 
                setObj({
                    age: Math.floor((new Date() - new Date(value)) / 1000 / 60 / 60 / 24 / 365),
                    updateVisiblity: Math.random()
                })
            }
        },
        {
            label: 'Age',
            key: 'age',
            type: 'number',
            placeholder: 'Enter Age',
            visible: true,
            width: '25%',
            moduleName: moduleName,
            tab: 'General',
            value: value?.date_of_birth ? Math.floor((new Date() - new Date(value?.date_of_birth)) / 1000 / 60 / 60 / 24 / 365) : "",
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                setObj({
                    date_of_birth: new Date(new Date().setFullYear(new Date().getFullYear() - value)),
                    updateVisiblity: Math.random()
                })
            }
        },
        {
            label: 'Gender',
            key: 'gender',
            type: 'select',
            placeholder: 'Select gender',
            visible: true,
            width: '25%',
            tab: 'General',
            options: [
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
                { label: "Others", value: "Others" }
            ]
        },
        {
            label: 'Do you sell to this contact?',
            key: 'is_customer',
            type: 'checkbox',
            placeholder: 'Enter the Opening Balance',
            visible: false,
            width: '100%',
            tab: 'General',
            value: true,
        },
        {
            label: 'Do you purchase from this contact?',
            key: 'is_supplier',
            type: 'checkbox',
            placeholder: 'Enter the Opening Balance',
            visible: false,
            width: '100%',
            tab: 'General',
            value: true,
        },
        // {
        //     type: 'divider',
        //     heading: 'GST Details',
        //     visible: true,
        //     span: 24,
        //     tab: 'General'
        // },
        {
            label: 'GST Treatment',
            key: 'gst_treatment',
            type: 'select',
            placeholder: 'Enter the GST Treatment',
            visible: false,
            width: '25%',
            options: [
                { label: 'Registered Business - Regular', value: 'Registered Business - Regular' },
                { label: 'Registered Business - Composition', value: 'Registered Business - Composition' },
                { label: 'Unregistered Business', value: 'Unregistered Business' },
                { label: 'Consumer', value: 'Consumer' },
            ],
            tab: 'General',
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random()
                });
            },
            value: 'Consumer'
        },
        // {
        //     label: 'PAN Number',
        //     key: 'pan_number',
        //     type: 'text',
        //     placeholder: 'Enter the PAN Number',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General'
        // },
        // {

        // },
        // {
        //     type: 'custom',
        //     key: 'gst_numbers',
        //     component: ExtraGSTNumbers,
        //     visible: (connectedObject) => {
        //         return (connectedObject.gst_treatment == 'Registered Business - Regular' || connectedObject.gst_treatment == 'Registered Business - Composition');
        //     },
        //     span: 24,
        //     value: [{
        //         gst_number: '',
        //         primary: 'Yes'
        //     }],
        //     tab: 'General'
        // },
        // addButton('Add GST Number', 'add_gst_number', 'gst_numbers', {
        //     gst_number: '',
        //     primary: 'No'
        // }, "General", (connectedObject) => {
        //     return (connectedObject.gst_treatment == 'Registered Business - Regular' || connectedObject.gst_treatment == 'Registered Business - Composition');
        // }),
        {
            type: 'divider',
            heading: 'Addresses',
            visible: true,
            span: 24,
            tab: 'General'
        }, {
            type: 'custom',
            key: 'addresses',
            component: AddressesComponent,
            visible: true,
            span: 24,
            value: [{
                primary_billing: 'Yes',
                primary_shipping: 'Yes',
                country: 'India',
                state: 'Madhya Pradesh',
                city: 'Bhopal'
            }],
            tab: 'General'
        },

        {
            label: 'Auto create ledger account with the same name as the contact?',
            key: 'auto_create_ledger_account',
            type: 'checkbox',
            placeholder: 'Enter the Opening Balance',
            visible: isEdit ? false : view ? false : true,
            width: '100%',
            tab: 'Ledger Account',
            value: isEdit ? false : true,
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random()
                })
            }
        },

        {
            key: 'visits',
            type: 'custom',
            visible: view ? response.module_preferences?.show_subscriptions == true && value?._id : false,
            width: '100%',
            tab: 'Visits',
            component: VisitsComponent
        },

        {
            key: 'appointments',
            type: 'custom',
            visible: view ? response.module_preferences?.show_subscriptions == true && value?._id : false,
            width: '100%',
            tab: 'Appointments',
            component: AppointmentComponent,
        },

        {
            key: 'medical_history',
            type: 'custom',
            visible: view ? response.module_preferences?.show_subscriptions == true && value?._id : false,
            width: '100%',
            tab: 'Medical History',
            component: MedicalHistoryComponent,
        },

        {
            key: 'active_subscriptions',
            type: 'custom',
            visible: view ? response.module_preferences?.show_subscriptions == true && value?._id : false,
            width: '100%',
            tab: 'Subscriptions',
            component: SubscriptionComponent,
        },

        {
            label: '',
            type: 'custom',
            placeholder: 'Enter the Ledger Account',
            visible: (connectedObject) => {
                if (view) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Ledger Account',
            component: LedgerAccountComponent
        },
    ];

    if (globalData.organization_id != 15) {
        formFields = formFields.filter((f) => f.tab != 'Eye Details');
    }

    if (view) {
        //hide general tab
        formFields = formFields.map((f) => {
            if (f.tab == 'General') {
                f.visible = false;
            }
            return f;
        });
    }

    const CustomFields = response.CustomFields;

    addCustomFields(CustomFields, formFields);

    return formFields;
}

export const contactMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

const preferencesFields = (response, view = false, moduleName = null, OpenModalCustom) => {
    const formFields = [{
        label: 'Show Subscriptions',
        key: 'show_subscriptions',
        type: 'checkbox',
        placeholder: 'Show Subscriptions',
        visible: true,
        width: '25%',
        value: false
    }];

    return formFields;
}

export const CustomViewHeader = (props) => {
    return <View padding-15 margin-10 marginB-0 paddingB-10 style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0, position: 'relative', height: 110 }}>
        <View spread row top>
            <View row >
                <Image source={{
                    uri: props?.connectedObject?.image ? props?.connectedObject?.image : 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png'
                }} style={{ width: 80, height: 80, borderRadius: 50 }} />
                <View marginL-10 marginT-10>
                    <Text white style={{
                        fontFamily: 'SourceSansProSemiBold'
                    }}>{props?.connectedObject?.name}</Text>
                    <Text white>{props?.connectedObject?.phone}</Text>
                    <Text white>Age - {props?.connectedObject?.age}</Text>
                    <Text white>Gender - {props?.connectedObject?.gender}</Text>
                </View>
            </View>

            {props.HeaderExtra}
        </View>
        <Image source={{
            uri: "https://img.freepik.com/free-vector/national-doctor-s-day-hand-drawn-background_23-2149438162.jpg?t=st=1722107221~exp=1722110821~hmac=f20aadab6949ac461d9e5e00b947cb124c5b809b415c42d1c07d5fbc57d90ff3&w=996"
        }} style={{
            width: '100%',
            height: 110,
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            borderRadius: 10,
            borderBottomEndRadius: 0,
            borderBottomStartRadius: 0,
        }} />
        <View
            style={{
                width: '100%',
                height: 150,
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: -1,
                borderRadius: 10,
                borderBottomEndRadius: 0,
                borderBottomStartRadius: 0,
                backgroundColor: 'rgba(0,0,0,0.8)'
            }}
        ></View>
    </View>;
}

export const contactMain = {
    fields: contactfields,
    CustomViewHeader: CustomViewHeader,
    mobileCard: null,
    preferencesFields: preferencesFields,
    CustomTitle: "Patients",
    // customExtraButtons: ({
    //     moduleName, obj, reloadModuleData, navigationFn, route, formUID, params,
    //     setOpenModal, openCustomModal, getModuleCreateData, goBack, message
    // }) => {
    //     return <View row marginR-10>
    //         <SecondaryButton
    //             label={"Book Appointment"}
    //             onPress={() => {

    //             }}
    //             style={{
    //                 marginRight: 10
    //             }}
    //         />
    //         <SecondaryButton
    //             label={"Record Invoice"}
    //             onPress={() => {
    //                 setOpenModal({
    //                     ["Invoices"]: {
    //                         isVisible: true,
    //                         moduleName: "Invoices",
    //                         viewOnly: false,
    //                         afterSaveEvent: params.afterSaveEvent ? params.afterSaveEvent : 'reloadListing',
    //                     }
    //                 })
    //             }}
    //             style={{
    //                 marginRight: 10
    //             }}
    //         />
    //         <SecondaryButton
    //             label={"Record Payment"}
    //             onPress={() => {

    //             }}
    //             style={{
    //                 marginRight: 10
    //             }}
    //         />
    //     </View>
    // }
    customActions: (moduleName, navigationFn, route, row, setOpenModal, openCustomModal) => {
        let options = [];

        options.push({
            label: 'Start Visit',
            key: 'book_appointment',
            fn: (props) => {
                if (Platform.OS === 'web') {
                    setOpenModal({
                        ["Appointments"]: {
                            isVisible: true,
                            moduleName: "Appointments",
                            afterSaveEvent: 'reloadListing',
                            is_default_values: true,
                            status: "Started",
                            slot: new Date(),
                            contact_id: row._id,
                        }
                    })
                } else {
                    navigationFn('New', {
                        moduleName: "Appointments",
                        goBackAfterSave: true,
                        is_default_values: true,
                        slot: new Date(),
                        contact_id: row._id,
                    });
                }
            }
        });

        options.push({
            label: 'Book Appointment',
            key: 'book_appointment',
            fn: (props) => {
                if (Platform.OS === 'web') {
                    setOpenModal({
                        ["Appointments"]: {
                            isVisible: true,
                            moduleName: "Appointments",
                            afterSaveEvent: 'reloadListing',
                            is_default_values: true,
                            contact_id: row._id,
                        }
                    })
                } else {
                    navigationFn('New', {
                        moduleName: "Appointments",
                        goBackAfterSave: true,
                        is_default_values: true,
                        contact_id: row._id,
                    });
                }
            }
        });

        options.push({
            label: 'Generate Invoice',
            key: 'generate_invoice',
            fn: (props) => {
                if (Platform.OS === 'web') {
                    setOpenModal({
                        ["Invoices"]: {
                            isVisible: true,
                            moduleName: "Invoices",
                            afterSaveEvent: 'reloadListing',
                            is_default_values: true,
                            contact_id: row._id,
                        }
                    })
                } else {
                    navigationFn('New', {
                        moduleName: "Invoices",
                        goBackAfterSave: true,
                        is_default_values: true,
                        contact_id: row._id,
                    });
                }
            }
        });

        options.push({
            label: 'Receive Payment',
            key: 'receive_payment',
            fn: (props) => {
                if (Platform.OS === 'web') {
                    setOpenModal({
                        ["PaymentReceived"]: {
                            isVisible: true,
                            moduleName: "PaymentReceived",
                            afterSaveEvent: 'reloadListing',
                            is_default_values: true,
                            contact_id: row._id,
                            total_amount: row.balance
                        }
                    })
                } else {
                    navigationFn('New', {
                        moduleName: "PaymentReceived",
                        goBackAfterSave: true,
                        is_default_values: true,
                        contact_id: row._id,
                        total_amount: row.balance
                    });
                }
            }
        });

        options = [
            ...options.map((item) => {
                return { ...item, ignore_permission: true }
            }),
        ]

        return options;
    }
}

export function addCustomFields(CustomFields, formFields) {
    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {

            const obj = {
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                tab: field.tab,
                onChange: null
            };

            const formFieldOne = formFields[0];

            if (!formFieldOne.tab) {
                delete obj.tab;
            }

            if (field.onChangeFunction) {
                obj.onChange = ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                    try {
                        const fn = api.decryptText(field.onChangeFunction)

                        eval(fn);
                    } catch (e) {

                    }
                }
            }

            if (field.after) {
                const index = formFields.findIndex((f) => f.key === field.after);
                formFields.splice(index + 1, 0, obj);
            } else if (field.before) {
                const index = formFields.findIndex((f) => f.key === field.before);
                formFields.splice(index, 0, obj);
            } else {
                formFields.push(obj)
            }

        });
    }
}
