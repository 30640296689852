import React, { useEffect, useState } from "react";
import { View, Card, Text, TouchableOpacity, Chip, Image } from "react-native-ui-lib";
import { Col, CustomAutoComplete, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, CustomUpload, DangerButton, Label, PrimaryButton, Row, SecondaryButton } from "../../mycomponents/DynamicForm";
import { Video, ResizeMode } from 'expo-av';
import { DeviceEventEmitter, Dimensions, Platform, ScrollView } from "react-native";
import Svg, { Rect, Path } from "react-native-svg";
import { CustomSelectItemForContacts, TransactionPDFPreview } from "../accountancy/transaction";
import { api } from "../../services/api";
import moment from "moment";
import ListingScreen from "../../screens/MainScreens/CRUD/ListingScreen";
import { Select } from "antd";
import { App } from 'antd';

export const appointmentfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData, message) => {

  const CourseContent = ({ field, useForm, tKey, tIndex }) => {

    return <View style={{
      width: '100%',
    }}>

    </View>
  }

  const InvoiceComponent = ({ field, useForm, tKey, tIndex }) => {
    const contact_id = useForm((state) => state.formObject['contact_id']);

    const props = {
      moduleName: 'Invoices',
      hideWrapper: true,
      extraFilters: { contact_id: contact_id ?? null },
    }

    const contact = response?.Contacts?.find((contact) => contact._id == contact_id);
    const title = contact ? "Invoices for " + contact.name : "Invoices";

    if (!contact_id) return <View w-100 flex>
      <Text>Please select a contact to view the invoices</Text>
    </View>;

    return <View w-100 flex>
      <ListingScreen {...props} title={title} default_values={{
        contact_id: contact_id,
        is_default_values: true
      }} />
    </View>
  };

  const SubscriptionComponent = ({ field, useForm, tKey, tIndex }) => {
    const contact_id = useForm((state) => state.formObject['contact_id']);
    const [subscriptions, setSubscriptions] = React.useState([]);

    const { message, modal } = App.useApp();

    const getSubscriptions = async () => {
      if (!contact_id) return;

      const responseData = await api.get("Subscriptions", {
        page: 1,
        limit: 1000,
        filters: [],
        tabFilter: { contact_id: contact_id },
        search: "",
        sortColumn: 'end_date',
        sortOrder: 'desc',
        groupBy: null
      });

      console.log("responseData", responseData);

      setSubscriptions(responseData.data.data);
    };

    useEffect(() => {
      getSubscriptions();
    }, [contact_id]);

    if (!contact_id) return <View w-100 flex>
      <Text>Please select a contact to view the subscriptions</Text>
    </View>;

    return <>
      <View flex row style={{
        flexWrap: 'wrap'
      }}>
        {subscriptions && subscriptions.length > 0 && subscriptions.map((subscription, i) => (<View key={i} style={{
          flexBasis: '33.33%',
        }}>
          <Card margin-10 padding-10 flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
            <View marginB-10 row spread style={{ width: '100%' }}>

              <View flex>
                <View row spread>
                  <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                    {subscription.item.name}
                  </Text>
                  {moment(subscription.end_date).isBefore(moment()) && <View style={{
                    backgroundColor: '#cb1721',
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: 5,
                  }}>
                    <Text style={{ color: 'white' }}>Expired</Text>
                  </View>}
                </View>


                {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                  <View
                    style={{
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      borderColor: '#d9d9d9',
                      margin: -2,
                      marginTop: 5,
                    }}>
                    <View style={{ height: 20, width: '100%' }} />
                  </View>
                </View> : <View style={{
                  borderBottomColor: '#d9d9d9',
                  borderBottomWidth: 1,
                  width: '100%',
                  marginBottom: 0,
                  marginTop: 5,
                  borderStyle: 'dashed',
                }} />}

                <View flex row marginT-10>
                  <View row marginB-6 flex centerV>
                    <View>
                      <Text lightCardTitle>{"Start Date"}: </Text>
                    </View>
                    <View flex>
                      <Text>{moment(subscription.start_date).format('DD MMM YYYY')}</Text>
                    </View>
                  </View>
                  <View row marginB-6 flex>
                    <View>
                      <Text lightCardTitle>{"Expiry Date"}: </Text>
                    </View>
                    <View flex>
                      <Text>{moment(subscription.end_date).format('DD MMM YYYY')}</Text>
                    </View>
                  </View>
                </View>

                {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                  <View
                    style={{
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      borderColor: '#d9d9d9',
                      margin: -2,
                      marginTop: 5,
                    }}>
                    <View style={{ height: 20, width: '100%' }} />
                  </View>
                </View> : <View style={{
                  borderBottomColor: '#d9d9d9',
                  borderBottomWidth: 1,
                  width: '100%',
                  marginBottom: 0,
                  marginTop: 5,
                  borderStyle: 'dashed',
                }} />}

                {subscription.subscription_item_usage.map((item, j) => (<View key={j} marginT-10>
                  <View flex>
                    <View row marginB-6 flex spread centerV>
                      <View>
                        <Text lightCardTitle>
                          {item.item?.name}
                        </Text>
                      </View>
                      <View flexG row centerV right>
                        <View marginR-5 style={{
                          width: 80,
                        }}>
                          <CustomNumberInput
                            type="number"
                            placeholder="PRP"
                            visible={true}
                            width="100%"
                            value={item.used_quantity}
                            onChange={(value) => {
                              const newSubscriptions = [...subscriptions];
                              const newSubscription = { ...newSubscriptions[i] };
                              const newSubscriptionItemUsage = [...newSubscription.subscription_item_usage];
                              const newSubscriptionItemUsageItem = { ...newSubscriptionItemUsage[j] };
                              newSubscriptionItemUsageItem.used_quantity = value;
                              newSubscriptionItemUsage[j] = newSubscriptionItemUsageItem;
                              newSubscription.subscription_item_usage = newSubscriptionItemUsage;
                              newSubscriptions[i] = newSubscription;
                              setSubscriptions(newSubscriptions);
                            }}
                          />
                        </View>
                        <Text>used out of </Text> <Text style={{ fontFamily: 'SourceSansProBold' }}>{item.quantity}</Text>
                      </View>
                    </View>
                  </View>
                </View>))}

                <View marginT-10>
                  <PrimaryButton
                    label="Update"
                    onPress={async () => {
                      modal.confirm({
                        title: 'Are you sure you want to update the usage?',
                        content: 'This action cannot be undone.',
                        okText: 'Yes',
                        cancelText: 'No',
                        onOk: async () => {
                          const response = await api.customRoute('Invoices/action', {
                            action: 'updateSubscriptionUsage',
                            _id: subscription.invoice_id,
                            newQuantityArray: subscriptions[i].subscription_item_usage
                          });

                          if (response.success) {
                            message.success('Updated successfully');
                          } else {
                            message.error('Error updating');
                          }
                        },
                        onCancel: () => {
                        }
                      });
                    }}
                  />
                </View>
              </View>
            </View>

          </Card>
        </View>))}
      </View>
    </>
  };

  const SubscriptionSummaryComponent = ({ field, useForm, tKey, tIndex }) => {
    const contact_id = useForm((state) => state.formObject['contact_id']);
    const [subscriptions, setSubscriptions] = React.useState([]);

    const { message, modal } = App.useApp();

    const getSubscriptions = async () => {
      if (!contact_id) return;

      const responseData = await api.get("Subscriptions", {
        page: 1,
        limit: 1000,
        filters: [],
        tabFilter: { contact_id: contact_id },
        search: "",
        groupBy: null,
        sortColumn: 'end_date',
        sortOrder: 'desc'
      });

      console.log("responseData", responseData);

      setSubscriptions(responseData.data.data);
    };

    useEffect(() => {
      getSubscriptions();
    }, [contact_id]);

    if (!contact_id) return <View w-100 flex>
      <Text>Please select a contact to view the subscriptions</Text>
    </View>;

    return <>
      <View key={contact_id} flex style={{
        flexWrap: 'wrap'
      }}>
        {subscriptions && subscriptions.length > 0 && subscriptions.map((subscription, i) => (!(moment(subscription.end_date).isBefore(moment())) ? <View key={i}>
          <Text style={{ marginBottom: 5, fontSize: 13, fontFamily: 'SourceSansProSemiBold' }}>
            {subscription.item.name + " - " + moment(subscription.start_date).format('DD MMM YYYY') + " to " + moment(subscription.end_date).format('DD MMM YYYY')}
          </Text>
        </View> : null))}
      </View>
    </>
  }

  const PDFComponent = ({ field, useForm, tKey, tIndex }) => {
    const pdf_number = useForm((state) => state.formObject['pdf_number']);
    const obj = useForm((state) => state.formObject);

    const { message, modal } = App.useApp();

    if (!pdf_number) return <View w-100 flex></View>;

    return <>
      <View flex style={{
        flexWrap: 'wrap'
      }}>
        <TransactionPDFPreview moduleName="Appointments" obj={obj} />
      </View>
    </>
  }

  const VitalsComponent = ({ field, useForm, tKey, tIndex }) => {
    const height = useForm((state) => state.formObject['height']);
    const height_unit = useForm((state) => state.formObject['height_unit']) || 'cm';
    const setFormObject = useForm((state) => state.setFormObject);

    return <View style={{
      width: '100%'
    }}>
      <View row style={{
        flexWrap: 'wrap',
        width: '100%'
      }}>
        <View style={{
          width: '25%',
        }}>
          <CustomTextField
            label="Height"
            key="height"
            type="number"
            placeholder="Height"
            visible={true}
            width="100%"
            value={height}
            onChange={(value) => {
              setFormObject({ height: value });
            }}
            suffixComponent={(
              <CustomAutoComplete
                label="Unit"
                key="unit"
                type="autocomplete"
                placeholder="Unit"
                visible={true}
                width="100%"
                value={height_unit}
                onChange={(value) => {
                  setFormObject({ height_unit: value });
                }}
                options={[{ label: 'cm', value: 'cm' }, { label: 'm', value: 'm' }]}
              />
            )}
          />
        </View>
      </View>
    </View>
  }

  const MedicalHistoryComponent = ({ field, useForm, tKey, tIndex }) => {
    const contact_id = useForm((state) => state.formObject['contact_id']);
    const [contact, setContact] = useState({});
    const [medicalHistory, setMedicalHistory] = useState([]);
    const [uploaded_documents, setUploadedDocuments] = useState([]);
    const setFormObject = useForm((state) => state.setFormObject);
    const obj = useForm((state) => state.formObject);
    const [loaded, setLoaded] = useState(false);

    const getData = async () => {
      const responseData = await api.get("Appointments", {
        page: 1,
        limit: 10,
        sortColumn: 'created_at',
        sortDirection: 'desc',
        filters: [],
        tabFilter: {
          contact_id: contact_id,
          status: "Completed"
        },
        search: "",
        groupBy: null
      });

      setMedicalHistory(responseData.data.data);
    }

    const getContact = async () => {
      const { data } = await api.getSingle("Contacts", {
        _id: contact_id,
      });

      setContact(data);

      if (data?.cf?.uploaded_documents) {
        setUploadedDocuments(data.cf.uploaded_documents);
      }
    }

    useEffect(() => {
      getData();
      getContact();
    }, []);

    useEffect(() => {
      const newObj = { ...contact };
      if (newObj['cf']) {
        newObj['cf']['uploaded_documents'] = uploaded_documents;
      } else {
        newObj['cf'] = {
          uploaded_documents: uploaded_documents
        }
      }

      if (contact.name) {
        api.update("Contacts", newObj, newObj._id).then((res) => {

        });
      }
    }, [uploaded_documents]);

    return <View flex row>
      <View row style={{
        flexWrap: 'wrap',
        flexBasis: '75%'
      }}>
        {medicalHistory && medicalHistory.length > 0 && medicalHistory.map((history, i) => (<View key={i} style={{
          flexBasis: '50%'
        }}>
          <Card margin-10 padding-10 flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
            <View marginB-10 row spread style={{ width: '100%' }}>

              <View flex>
                <View row spread>
                  <Text style={{ marginBottom: 5, fontSize: 13, fontFamily: 'SourceSansProBold' }}>
                    Date:
                  </Text>
                  <Text right style={{ marginBottom: 5, fontSize: 13 }}>
                    {moment(history.date).format('DD MMM YYYY hh:mm A')}
                  </Text>
                </View>
                <View row spread>
                  <Text style={{ marginBottom: 5, fontSize: 13, fontFamily: 'SourceSansProBold' }}>
                    Symptoms:
                  </Text>
                  <Text right style={{ marginBottom: 5, fontSize: 13 }}>
                    {history.symptoms.length > 0 ? history.symptoms.map((symptom) => symptom.name).join(', ') : '-'}
                  </Text>
                </View>
                <View row spread>
                  <Text right style={{ marginBottom: 5, fontSize: 13, fontFamily: 'SourceSansProBold' }}>
                    Diagnosis:
                  </Text>
                  <Text right style={{ marginBottom: 5, fontSize: 13 }}>
                    {history.diagnosis.length > 0 ? history.diagnosis.map((diagnosis) => diagnosis.name).join(', ') : '-'}
                  </Text>
                </View>
                <View row spread>
                  <Text right style={{ marginBottom: 5, fontSize: 13, fontFamily: 'SourceSansProBold' }}>
                    Medication:
                  </Text>
                  <Text style={{ marginBottom: 5, fontSize: 13 }}>
                    {history.medications.length > 0 ? history.medications.map((medication) => medication.medicine).join(', ') : '-'}
                  </Text>
                </View>

                <View style={{
                  width: '100%',
                }}>
                  <SecondaryButton style={{
                    width: '100%',
                  }} label="Copy" onPress={() => {
                    // copy symptoms, diagnosis, lab_investigations, examination, medications, custom_instructions
                    setFormObject({
                      symptoms: history.symptoms,
                      diagnosis: history.diagnosis,
                      lab_investigations: history.lab_investigations,
                      examination: history.examination,
                      medications: history.medications,
                      custom_instructions: history.custom_instructions,
                      updateVisiblity: Math.random()
                    });

                    message.success('Copied successfully');
                  }} />
                </View>
              </View>
            </View>
          </Card>
        </View>))}
      </View>
      <View style={{
        flexWrap: 'wrap',
        flexBasis: '25%'
      }}>
        {Label({ label: 'Old Documents' })}
        <CustomUpload
          moduleName="Contacts"
          multiple={true}
          value={uploaded_documents}
          mimeType="*/*"
          onChange={(value) => {
            setUploadedDocuments(value);
          }}
          showNaming={true}
        />
      </View>
    </View>
  }

  const symptomsColumns = [
    {
      "field": "name",
      "accessor": "name",
      "label": "Name",
      "Header": "Name",
      "editable": view ? false : true,
      "type": "autocomplete",
      "visible": true,
      "order": 1,
      "options": [],
      "auto_fill_key": 'symptoms',
    },
    {
      "field": "since",
      "accessor": "since",
      "label": "Since",
      "Header": "Since",
      "editable": true,
      "type": "duration",
      "duration_options": ["days", "weeks", "months", "years"],
      "visible": true,
      "order": 2,
    },
    {
      "field": "severity",
      "accessor": "severity",
      "label": "Severity",
      "Header": "Severity",
      "editable": view ? false : true,
      "type": "select",
      "visible": true,
      "order": 3,
      "options": [
        { label: 'Mild', value: 'Mild' },
        { label: 'Moderate', value: 'Moderate' },
        { label: 'Severe', value: 'Severe' },
      ]
    },
    {
      "field": "details",
      "accessor": "details",
      "label": "Details",
      "Header": "Details",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 5,
    }
  ];

  const diagnosisColumns = [
    {
      "field": "name",
      "accessor": "name",
      "label": "Name",
      "Header": "Name",
      "editable": true,
      "type": "autocomplete",
      "visible": true,
      "order": 1,
      "options": [],
      "auto_fill_key": 'diagnosis',
    },
    {
      "field": "since",
      "accessor": "since",
      "label": "Since",
      "Header": "Since",
      "editable": true,
      "type": "duration",
      "duration_options": ["weeks", "months", "years"],
      "visible": true,
      "order": 2,
    },
    {
      "field": "status",
      "accessor": "status",
      "label": "Status",
      "Header": "Status",
      "editable": true,
      "type": "select",
      "visible": true,
      "order": 3,
      "options": [
        { label: 'Ruled Out', value: 'Ruled Out' },
        { label: 'Suspected', value: 'Suspected' },
        { label: 'Confirmed', value: 'Confirmed' },
      ]
    },
    {
      "field": "details",
      "accessor": "details",
      "label": "Details",
      "Header": "Details",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 5,
    }
  ];

  const labInvestigationsColumns = [
    {
      "field": "name",
      "accessor": "name",
      "label": "Name",
      "Header": "Name",
      "editable": true,
      "type": "autocomplete",
      "auto_fill_key": 'lab_investigations',
      "visible": true,
      "order": 1,
      "options": [],
    },
    {
      "field": "test_on",
      "accessor": "test_on",
      "label": "Test On",
      "Header": "Test On",
      "editable": true,
      "type": "date",
      "visible": true,
      "order": 2,
    },
    {
      "field": "repeat_on",
      "accessor": "repeat_on",
      "label": "Repeat On",
      "Header": "Repeat On",
      "editable": true,
      "type": "date",
      "visible": true,
      "order": 3,
    },
    {
      "field": "details",
      "accessor": "details",
      "label": "Details",
      "Header": "Details",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 5,
    }
  ];

  const examinationColumns = [
    {
      "field": "name",
      "accessor": "name",
      "label": "Name",
      "Header": "Name",
      "editable": true,
      "type": "autocomplete",
      "visible": true,
      "order": 1,
      "options": [{ label: 'Symptom 1', value: 'Symptom 1' }, { label: 'Symptom 2', value: 'Symptom 2' }],
    },
    {
      "field": "details",
      "accessor": "details",
      "label": "Details",
      "Header": "Details",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 5,
    }
  ];

  const medicationsColumns = [
    {
      "field": "medicine",
      "accessor": "medicine",
      "label": "Medicine",
      "Header": "Medicine",
      "editable": true,
      "type": "autocomplete",
      "auto_fill_key": 'medicines',
      "visible": true,
      "order": 1,
      "options": [],
    },
    {
      "field": "potency",
      "accessor": "potency",
      "label": "Potency",
      "Header": "Potency",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 2,
    },
    {
      "field": "frequency",
      "accessor": "frequency",
      "label": "Frequency",
      "Header": "Frequency",
      "editable": true,
      "type": "autocomplete",
      "options": [
        { label: 'Once a day', value: 'Once a day' },
        { label: 'Once every 2 days', value: 'Once every 2 days' },
        { label: 'Once every 3 days', value: 'Once every 3 days' },
        { label: 'Once every 4 days', value: 'Once every 4 days' },
        { label: 'Once every 5 days', value: 'Once every 5 days' },
        { label: 'Once every 6 days', value: 'Once every 6 days' },
        { label: 'Once every 7 days', value: 'Once every 7 days' },
        { label: 'Twice a day', value: 'Twice a day' },
        { label: 'Thrice a day', value: 'Thrice a day' },
      ],
      "visible": true,
      "order": 3,
    },
    {
      "field": "timing",
      "accessor": "timing",
      "label": "Timing",
      "Header": "Timing",
      "editable": true,
      "type": "autocomplete",
      "options": [
        { label: 'Morning', value: 'Morning' },
        { label: 'Afternoon', value: 'Afternoon' },
        { label: 'Evening', value: 'Evening' },
        { label: 'Night', value: 'Night' },
        { label: 'Morning and Evening', value: 'Morning and Evening' },
        { label: 'Morning, Afternoon and Night', value: 'Morning, Afternoon and Night' },
        { label: 'Morning and Night', value: 'Morning and Night' }
      ],
      "visible": true,
      "order": 4,
    },
    {
      "field": "duration",
      "accessor": "duration",
      "label": "Duration",
      "Header": "Duration",
      "editable": true,
      "type": "duration",
      "duration_options": ["hours", "days", "weeks", "months", "years"],
      "visible": true,
      "order": 5,
      "onChange": ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        // based on duration and start date if both are present, calculate end date and that will be follow up date of the appointment
        let highest_end_date = null;

        const medications = getObj()["medications"];

        for (let i = 0; i < medications.length; i++) {
          const medication = medications[i];
          if (medication.duration && medication.start_from) {
            const end_date = moment(medication.start_from).add(medication.duration, 'ms');
            if (highest_end_date == null || end_date > highest_end_date) {
              highest_end_date = end_date;
            }
          }
        }

        if (highest_end_date) {
          setObj({
            follow_up_date: highest_end_date, updateVisiblity: Math.random()
          });
        }
      }
    },
    {
      "field": "start_from",
      "accessor": "start_from",
      "label": "Start From",
      "Header": "Start From",
      "editable": true,
      "type": "date",
      "visible": true,
      "order": 6,
      "onChange": ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        // based on duration and start date if both are present, calculate end date and that will be follow up date of the appointment
        let highest_end_date = null;

        const medications = getObj()["medications"];

        for (let i = 0; i < medications.length; i++) {
          const medication = medications[i];
          if (medication.duration && medication.start_from) {
            const end_date = moment(medication.start_from).add(medication.duration, 'ms');
            if (highest_end_date == null || end_date > highest_end_date) {
              highest_end_date = end_date;
            }
          }
        }

        if (highest_end_date) {
          setObj({
            follow_up_date: highest_end_date,
            updateVisiblity: Math.random()
          });
        }
      }
    },
    {
      "field": "instructions",
      "accessor": "instructions",
      "label": "Instructions",
      "Header": "Instructions",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 7,
    }
  ];

  const customInstructionsColumns = [
    {
      "field": "particulars",
      "accessor": "particulars",
      "label": "Particulars",
      "Header": "Particulars",
      "editable": true,
      "type": "autocomplete",
      "auto_fill_key": 'custom_instructions',
      "visible": true,
      "order": 1,
      "options": [],
    },
    {
      "field": "instructions",
      "accessor": "instructions",
      "label": "Instructions",
      "Header": "Instructions",
      "editable": true,
      "type": "text",
      "visible": true,
      "order": 2,
    }
  ];

  let formFields = [
    {
      label: 'Contact',
      key: 'contact_id',
      type: 'select',
      placeholder: 'Select the contact',
      visible: true,
      width: '25%',
      options: response.Contacts || [],
      tab: 'General',
      creatableAction: (props) => {
        const params = {
          ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? {
            is_default_values: true,
            phone: props.search,
            is_supplier: false,
          } : {})
        };
        OpenModalCustom({ newModule: "Contacts", field_key: 'contact_id', params });
      },
      customSelectItem: CustomSelectItemForContacts,
      otherSearchKeys: ['phone', 'email'],
      autoFocus: value?.contact_id ? false : true,
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        setObj({
          updateVisiblity: Math.random(),
        })
      }
    },
    // start_date_time
    {
      label: 'Date',
      key: 'date',
      type: 'date',
      placeholder: 'Date',
      visible: true,
      width: '25%',
      tab: 'General',
      value: new Date(),
    },

    {
      label: 'Employee',
      key: 'employee_id',
      type: 'select',
      placeholder: 'Select the employee',
      visible: true,
      tab: 'General',
      width: '25%',
      options: (response.Employees || []).map((item) => {
        return {
          ...item,
          label: item.name,
          value: item._id
        }
      }),
      value: globalData.employee_id ? response.Employees.find((item) => item._id == globalData.employee_id)?._id : null
    },

    {
      label: 'Duration',
      key: 'duration',
      type: 'duration',
      duration_options: ["minutes", "hours"],
      placeholder: 'duration',
      visible: true,
      width: '25%',
      tab: 'General',
      value: 1800000,
    },

    {
      label: 'Slot',
      key: 'slot',
      type: 'date',
      placeholder: 'Slot',
      visible: false,
      width: '25%',
      tab: 'General',
      value: new Date(Math.ceil(new Date().getTime() / (1000 * 60 * 15)) * (1000 * 60 * 15)),
    },

    {
      label: 'Type',
      key: 'type',
      type: 'select',
      options: [{ label: 'First Visit', value: 'First Visit' }, { label: 'Follow Up', value: 'Follow Up' }],
      placeholder: 'Type',
      visible: true,
      width: '25%',
      tab: 'General',
      value: "First Visit",
    },

    {
      label: 'Status',
      key: 'status',
      type: 'select',
      options: [{ label: 'Scheduled', value: 'Scheduled' }, { label: 'Started', value: 'Started' }, { label: 'Completed', value: 'Completed' }, { label: 'Cancelled', value: 'Cancelled' }],
      placeholder: 'Status',
      visible: true,
      width: '25%',
      tab: 'General',
      value: "Scheduled",
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        setObj({ updateVisiblity: Math.random() });
      }
    },

    {
      label: 'Active Subscruptions',
      key: 'active_subscriptions',
      type: 'custom',
      visible: true,
      width: '25%',
      tab: 'General',
      component: SubscriptionSummaryComponent,
    },



    {
      type: 'custom',
      visible: true,
      width: '100%',
      tab: 'General',
      component: ({ field, useForm, tKey, tIndex }) => {
        const date = useForm((state) => state.formObject['date']);
        const duration = useForm((state) => state.formObject['duration']);
        const employee_id = useForm((state) => state.formObject['employee_id']);
        const setFormObject = useForm((state) => state.setFormObject);
        const slots = useForm((state) => state.formObject['slots']);
        const slot = useForm((state) => state.formObject['slot']);

        useEffect(() => {
          getSlots();

          async function getSlots() {
            if (date && duration && employee_id) {
              await api.customRoute("Appointments/action", {
                action: "getSlots",
                employee_id: employee_id,
                date: date,
                duration: duration,
              }).then((res) => {
                console.log("res", res);
                const available_slots = res.available_slots;

                const current_time = moment();
                const current_time_ms = current_time.valueOf();

                // Filter out past slots
                const future_slots = available_slots.filter(slot => moment(slot).valueOf() > current_time_ms);

                let closest_slot = null;
                let smallest_diff = Infinity;

                future_slots.forEach((slot) => {
                  const diff = Math.abs(moment(slot).valueOf() - current_time_ms);
                  if (diff < smallest_diff) {
                    smallest_diff = diff;
                    closest_slot = slot;
                  }
                });

                setFormObject({ slots: future_slots, slot: slot && future_slots.includes(slot) ? slot : closest_slot });
              });
            }
          }
        }, [date, duration, employee_id]);

        return (
          <View style={{ width: '100%' }}>
            {Label({ label: 'Slot' })}
            {!view ? (
              <View row style={{ flexWrap: 'wrap', width: '100%', marginLeft: -2 }}>
                {(slots || []).length > 0 ? (
                  slots.map((single_slot) => {
                    const slotLabel = `${moment(single_slot).format('hh:mm A')} - ${moment(single_slot).add(duration, 'ms').format('hh:mm A')}`;
                    return (
                      <TouchableOpacity
                        key={single_slot}
                        margin-5
                        style={{ width: Platform.OS == 'web' ? 150 : '50%' }}
                        onPress={() => setFormObject({ slot: single_slot })}
                      >
                        <Chip
                          labelStyle={{
                            fontSize: 14,
                            lineHeight: 22,
                            fontFamily: 'SourceSansProSemiBold',
                            color: single_slot == slot ? '#5382ca' : '#2c2c2c',
                          }}
                          containerStyle={{
                            borderColor: single_slot == slot ? '#8faedd' : '#efeff1',
                            borderWidth: 2,
                            height: 40,
                          }}
                          label={slotLabel}
                          backgroundColor={single_slot == slot ? 'white' : 'white'}
                          borderRadius={4}
                        />
                      </TouchableOpacity>
                    );
                  })
                ) : (
                  <View margin-2><Text>No slots available</Text></View>
                )}
              </View>
            ) : (
              <View margin-2>
                <Text>
                  {slot ? `${moment(slot).format('hh:mm A')} - ${moment(slot).add(duration, 'ms').format('hh:mm A')}` : 'No slot selected'}
                </Text>
              </View>
            )}
          </View>
        );
      }
    },

    {
      type: 'divider',
      heading: 'Symptoms',
      icon: 'https://pub-ed4dcf9e4976449fa869f76b2ec53b5d.r2.dev/2024/May/Items/409694121141013300000.png',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      span: 24,
      tab: 'Prescription Pad'
    },

    {
      key: 'symptoms',
      type: 'table',
      compact: true,
      placeholder: 'duration',
      visible: (connectedObject) => (connectedObject.symptoms?.length > 0 && (connectedObject.status == "Started" || connectedObject.status == "Completed")),
      width: '100%',
      tab: 'Prescription Pad',
      value: [],
      columns: symptomsColumns,
      actions: view ? false : true,
      editable: view ? false : true,
      forceUpdate: true,
      actionFunctions: (row) => ([{
        label: 'Delete',
        key: 'delete',
        fn: ({ data, updateData, row, row_index }) => {
          let newData = [...data];
          newData.splice(row_index, 1);
          updateData(newData);
        }
      }]),
    },
    {
      label: '',
      key: 'symptoms_add',
      type: 'autocomplete',
      placeholder: 'Add Symptoms',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '100%',
      auto_fill_key: 'symptoms',
      options: [],
      tab: 'Prescription Pad',
      onSelect: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        const symptoms = [...(getObj()["symptoms"] || [])];

        symptoms.push({
          name: value,
          since: null,
          severity: '',
          details: '',
        });

        setObj({
          symptoms: symptoms,
          symptoms_add: '',
          updateVisiblity: Math.random()
        });
      }
    },

    {
      type: 'divider',
      heading: 'Vitals',
      icon: "https://pub-ed4dcf9e4976449fa869f76b2ec53b5d.r2.dev/2024/May/Items/957009166959122300000.png",
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      span: 24,
      tab: 'Prescription Pad'
    },

    {
      label: 'Pulse rate',
      key: 'vitals___pulse_rate',
      type: 'text',
      placeholder: 'Pulse rate',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Peripheral oxygen saturation',
      key: 'vitals___peripheral_oxygen_saturation',
      type: 'text',
      placeholder: 'Peripheral oxygen saturation',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Blood pressure',
      key: 'vitals___blood_pressure',
      type: 'text',
      placeholder: 'Blood pressure',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Respiratory rate',
      key: 'vitals___respiratory_rate',
      type: 'text',
      placeholder: 'Respiratory rate',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Body Temperature',
      key: 'vitals___body_temperature',
      type: 'text',
      placeholder: 'Body Temperature',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Body height (cm)',
      key: 'vitals___body_height',
      type: 'text',
      placeholder: 'Body height',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        //calculate BMI based on height cm and weight kg
        const body_height = value;
        const body_weight = getObj()["vitals___body_weight"];
        if (body_height && body_weight) {
          const bmi = (body_weight / ((body_height / 100) * (body_height / 100))).toFixed(2);
          setObj({ vitals___body_mass_index: bmi, updateVisiblity: Math.random() });
        }
      }
    },

    {
      label: 'Body weight (kg)',
      key: 'vitals___body_weight',
      type: 'text',
      placeholder: 'Body weight',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        //calculate BMI based on height cm and weight kg
        const body_weight = value;
        const body_height = getObj()["vitals___body_height"];
        if (body_height && body_weight) {
          const bmi = (body_weight / ((body_height / 100) * (body_height / 100))).toFixed(2);
          setObj({ vitals___body_mass_index: bmi, updateVisiblity: Math.random() });
        }
      }
    },

    {
      label: 'Body mass index',
      key: 'vitals___body_mass_index',
      type: 'text',
      placeholder: 'Body mass index',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Systolic blood pressure',
      key: 'vitals___systolic_blood_pressure',
      type: 'text',
      placeholder: 'Systolic blood pressure',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Diastolic blood pressure',
      key: 'vitals___diastolic_blood_pressure',
      type: 'text',
      placeholder: 'Diastolic blood pressure',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Blood sugar',
      key: 'vitals___blood_sugar',
      type: 'text',
      placeholder: 'Blood sugar',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
    },
    {
      label: 'Last menstrual phase (LMP)',
      key: 'vitals___last_menstrual_phase',
      type: 'date',
      placeholder: 'Last menstrual phase (LMP)',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
      onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        //calculate EDD based on LMP
        const lmp = value;
        if (lmp) {
          const edd = moment(lmp).add(280, 'days').format('YYYY-MM-DD');
          setObj({ vitals___edd: edd, updateVisiblity: Math.random() });
        }
      }
    },

    {
      label: 'EDD',
      key: 'vitals___edd',
      type: 'date',
      placeholder: 'EDD',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      type: 'divider',
      heading: 'Diagnosis',
      icon: "https://pub-ed4dcf9e4976449fa869f76b2ec53b5d.r2.dev/2024/May/Items/535048066152568000000.png",
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      span: 24,
      tab: 'Prescription Pad'
    },

    {
      key: 'diagnosis',
      type: 'table',
      compact: true,
      visible: (connectedObject) => connectedObject.diagnosis?.length > 0 && (connectedObject.status == "Started" || connectedObject.status == "Completed"),
      width: '100%',
      tab: 'Prescription Pad',
      value: [],
      columns: diagnosisColumns,
      actions: true,
      forceUpdate: true,
      actionFunctions: (row) => ([{
        label: 'Delete',
        key: 'delete',
        fn: ({ data, updateData, row, row_index }) => {
          let newData = [...data];
          newData.splice(row_index, 1);
          updateData(newData);
        }
      }]),
    },
    {
      label: '',
      key: 'diagnosis_add',
      type: 'autocomplete',
      placeholder: 'Add Diagnosis',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '100%',
      options: [],
      auto_fill_key: 'diagnosis',
      tab: 'Prescription Pad',
      onSelect: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        const diagnosis = [...(getObj()["diagnosis"] || [])];

        diagnosis.push({
          name: value,
          since: null,
          status: '',
          details: '',
        });

        setObj({
          diagnosis: diagnosis,
          diagnosis_add: '',
          updateVisiblity: Math.random()
        });
      }
    },

    {
      type: 'divider',
      heading: 'Lab Investigations',
      icon: "https://pub-ed4dcf9e4976449fa869f76b2ec53b5d.r2.dev/2024/May/Items/308098860392572600000.png",
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      span: 24,
      tab: 'Prescription Pad'
    },

    {
      key: 'lab_investigations',
      type: 'table',
      compact: true,
      placeholder: 'duration',
      visible: (connectedObject) => connectedObject.lab_investigations?.length > 0 && (connectedObject.status == "Started" || connectedObject.status == "Completed"),
      width: '100%',
      tab: 'Prescription Pad',
      value: [],
      columns: labInvestigationsColumns,
      actions: true,
      forceUpdate: true,
      actionFunctions: (row) => ([{
        label: 'Delete',
        key: 'delete',
        fn: ({ data, updateData, row, row_index }) => {
          let newData = [...data];
          newData.splice(row_index, 1);
          updateData(newData);
        }
      }]),
    },

    {
      label: '',
      key: 'lab_investigations_add',
      type: 'autocomplete',
      placeholder: 'Add Lab Investigations',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '100%',
      options: [],
      tab: 'Prescription Pad',
      auto_fill_key: 'lab_investigations',
      onSelect: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        const lab_investigations = [...(getObj()["lab_investigations"] || [])];

        lab_investigations.push({
          name: value,
          test_on: null,
          repeat_on: null,
          details: '',
        });

        setObj({
          lab_investigations: lab_investigations,
          lab_investigations_add: '',
          updateVisiblity: Math.random()
        });
      }
    },

    {
      type: 'divider',
      heading: 'Medications',
      icon: "https://pub-ed4dcf9e4976449fa869f76b2ec53b5d.r2.dev/2024/May/Items/390748846986808500000.png",
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      span: 24,
      tab: 'Prescription Pad'
    },

    {
      key: 'medications',
      type: 'table',
      compact: true,
      placeholder: 'duration',
      visible: (connectedObject) => connectedObject.medications?.length > 0 && (connectedObject.status == "Started" || connectedObject.status == "Completed"),
      width: '100%',
      tab: 'Prescription Pad',
      value: [],
      columns: medicationsColumns,
      actions: true,
      forceUpdate: true,
      actionFunctions: (row) => ([{
        label: 'Delete',
        key: 'delete',
        fn: ({ data, updateData, row, row_index }) => {
          let newData = [...data];
          newData.splice(row_index, 1);
          updateData(newData);
        }
      }]),
    },

    {
      label: '',
      key: 'medications_add',
      type: 'autocomplete',
      placeholder: 'Add Medications',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '100%',
      options: [],
      tab: 'Prescription Pad',
      auto_fill_key: 'medicines',
      onSelect: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        const medications = [...(getObj()["medications"] || [])];

        medications.push({
          medicine: value,
        });

        setObj({
          medications: medications,
          medications_add: '',
          updateVisiblity: Math.random()
        });
      }
    },

    {
      type: 'divider',
      heading: 'Follow Up Details',
      icon: "https://pub-ed4dcf9e4976449fa869f76b2ec53b5d.r2.dev/2024/May/Items/202802062606409660000.png",
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      span: 24,
      tab: 'Prescription Pad'
    },

    {
      label: 'No Follow Up?',
      key: 'no_follow_up',
      type: 'checkbox',
      placeholder: 'No Follow Up?',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Follow Up Date',
      key: 'follow_up_date',
      type: 'date',
      placeholder: 'Follow Up Date',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '25%',
      tab: 'Prescription Pad',
    },

    {
      label: 'Follow Up Text',
      key: 'follow_up_text',
      type: 'text',
      placeholder: 'Follow Up Text',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '50%',
      tab: 'Prescription Pad',
    },

    {
      type: 'divider',
      heading: 'Extra Instructions',
      icon: "https://pub-ed4dcf9e4976449fa869f76b2ec53b5d.r2.dev/2024/May/Items/480926737024709950000.png",
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      span: 24,
      tab: 'Prescription Pad'
    },

    {
      key: 'custom_instructions',
      type: 'table',
      compact: true,
      visible: (connectedObject) => connectedObject.custom_instructions?.length > 0 && (connectedObject.status == "Started" || connectedObject.status == "Completed"),
      width: '100%',
      tab: 'Prescription Pad',
      value: [],
      columns: customInstructionsColumns,
      actions: true,
      forceUpdate: true,
      actionFunctions: (row) => ([{
        label: 'Delete',
        key: 'delete',
        fn: ({ data, updateData, row, row_index }) => {
          let newData = [...data];
          newData.splice(row_index, 1);
          updateData(newData);
        }
      }]),
    },
    {
      label: '',
      key: 'custom_instructions_add',
      type: 'autocomplete',
      placeholder: 'Add Custom Instructions',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '100%',
      options: [],
      tab: 'Prescription Pad',
      auto_fill_key: 'custom_instructions',
      onSelect: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        const custom_instructions = [...(getObj()["custom_instructions"] || [])];

        custom_instructions.push({
          particulars: value,
          instructions: '',
        });

        setObj({
          custom_instructions: custom_instructions,
          custom_instructions_add: '',
          updateVisiblity: Math.random()
        });
      }
    },

    {
      key: 'medical_history',
      type: 'custom',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '100%',
      tab: 'Medical History',
      component: MedicalHistoryComponent,
    },

    {
      type: 'custom',
      visible: (connectedObject) => connectedObject.status == "Started" || connectedObject.status == "Completed",
      width: '100%',
      tab: 'Billing',
      component: InvoiceComponent,
    },

    {
      key: 'active_subscriptions',
      type: 'custom',
      visible: true,
      width: '100%',
      tab: 'Subscriptions',
      component: SubscriptionComponent,
    },

    {
      label: 'PDF Preview',
      key: 'pdf_preview',
      type: 'custom',
      visible: (connectedObject) => connectedObject.status == "Completed",
      width: '100%',
      tab: 'PDF Preview',
      component: PDFComponent,
    },
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  // if value?.status is started or completed, then hide general tab
  if (value?.status == "Started" || value?.status == "Completed") {
    formFields = formFields.map((item) => {
      if (item.tab == 'General') {
        return {
          ...item,
          visible: false
        }
      }

      return item;
    });
  }

  return formFields;
}

export const appointmentMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

const ExtraSaveComponent = (props) => {
  const { getFormObject, setFormObject, saveFnInternal, setCurrentTab } = props;
  const [showModal, setShowModal] = useState(false);

  const status = getFormObject().status;
  const obj = getFormObject();

  return status == "Started" ? <><SecondaryButton
    style={{ marginLeft: 10 }}
    onPress={async () => {
      setFormObject({
        no_follow_up: true,
        status: 'Completed'
      });
      await saveFnInternal(true);
      setShowModal(true);
    }} label={"End Visit"}></SecondaryButton>
  </> : null;
}

const CustomViewHeader = (props) => {
  return <View padding-15 margin-10 marginB-0 paddingB-10 style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0, position: 'relative', height: 110 }}>
    <View spread row top>
      <View row >
        <Image source={{
          uri: props?.connectedObject?.contact?.image ? props?.connectedObject?.contact?.image : 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png'
        }} style={{ width: 80, height: 80, borderRadius: 50 }} />
        <View marginL-10 marginT-10>
          <Text white style={{
            fontFamily: 'SourceSansProSemiBold'
          }}>{props?.connectedObject?.contact?.name}</Text>
          <Text white>{props?.connectedObject?.contact?.phone}</Text>
          <Text white>Age - {props?.connectedObject?.contact?.age}</Text>
          <Text white>Gender - {props?.connectedObject?.contact?.gender}</Text>
        </View>
      </View>

      {props.HeaderExtra}
    </View>
    <Image source={{
      uri: "https://img.freepik.com/free-vector/national-doctor-s-day-hand-drawn-background_23-2149438162.jpg?t=st=1722107221~exp=1722110821~hmac=f20aadab6949ac461d9e5e00b947cb124c5b809b415c42d1c07d5fbc57d90ff3&w=996"
    }} style={{
      width: '100%',
      height: 110,
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: -1,
      borderRadius: 10,
      borderBottomEndRadius: 0,
      borderBottomStartRadius: 0,
    }} />
    <View
      style={{
        width: '100%',
        height: 150,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        borderRadius: 10,
        borderBottomEndRadius: 0,
        borderBottomStartRadius: 0,
        backgroundColor: 'rgba(0,0,0,0.8)'
      }}
    ></View>
  </View>;
}

const CustomEditHeader = (props) => {
  return <View padding-15 margin-10 marginB-0 paddingB-10 style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0, position: 'relative', height: 110 }}>
    <View spread row top>
      <View row >
        <Image source={{
          uri: props?.connectedObject?.contact?.image ? props?.connectedObject?.contact?.image : 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png'
        }} style={{ width: 80, height: 80, borderRadius: 50 }} />
        <View marginL-10 marginT-10>
          <Text white style={{
            fontFamily: 'SourceSansProSemiBold'
          }}>{props?.connectedObject?.contact?.name}</Text>
          <Text white>{props?.connectedObject?.contact?.phone}</Text>
          <Text white>Age - {props?.connectedObject?.contact?.age}</Text>
          <Text white>Gender - {props?.connectedObject?.contact?.gender}</Text>
        </View>
      </View>

      {props.HeaderExtra}
    </View>
    <Image source={{
      uri: "https://img.freepik.com/free-vector/national-doctor-s-day-hand-drawn-background_23-2149438162.jpg?t=st=1722107221~exp=1722110821~hmac=f20aadab6949ac461d9e5e00b947cb124c5b809b415c42d1c07d5fbc57d90ff3&w=996"
    }} style={{
      width: '100%',
      height: 110,
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: -1,
      borderRadius: 10,
      borderBottomEndRadius: 0,
      borderBottomStartRadius: 0,
    }} />
    <View
      style={{
        width: '100%',
        height: 150,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        borderRadius: 10,
        borderBottomEndRadius: 0,
        borderBottomStartRadius: 0,
        backgroundColor: 'rgba(0,0,0,0.8)'
      }}
    ></View>
  </View>;
}

export const appointmentMain = {
  fields: appointmentfields,
  mobileCard: null,
  customColumnRender: {
    status: (value, row, column, navigationFn, setOpenModal) => {
      let color = '#007bff';

      if (row.status) {
        if (row.status == 'Scheduled') {
          color = '#ffa600';
        } else if (row.status == 'Started') {
          color = '#007bff';
        } else if (row.status == 'Completed') {
          color = '#239523';
        } else if (row.status == 'Cancelled') {
          color = '#ff0000';
        }
      }

      const tinycolor = require("tinycolor2");
      const lighterColor = tinycolor(color).lighten(45).toString();

      return <View left>
        <View paddingH-8 paddingV-3 style={{ backgroundColor: lighterColor, borderRadius: 5 }}>
          <Text
            monoTableBody={false}
            monoTableHeading={false}
            style={[
              column.customCellStyle,
              { maxWidth: '100%' },
              { color: color },
              { fontFamily: 'SourceSansProSemiBold' }
            ]} ellipsizeMode={'tail'} numberOfLines={1}>
            {value}
          </Text>
        </View>
      </View>
    },
    contact_balance: (value, row, column, navigationFn, setOpenModal) => {
      let color = null

      if (row.contact_balance > 0) {
        color = '#ff0000';
      } else if (row.contact_balance < 0) {
        color = '#ff0000';
      } else {
        // dark greenish color tag
        color = '#239523';
      }

      const tinycolor = require("tinycolor2");
      const lighterColor = tinycolor(color).lighten(45).toString();

      return <View left>
        <View paddingH-8 paddingV-3 style={{ backgroundColor: lighterColor, borderRadius: 5 }}>
          <Text
            monoTableBody={false}
            monoTableHeading={false}
            style={[
              column.customCellStyle,
              { maxWidth: '100%' },
              { color: color },
              { fontFamily: 'SourceSansProSemiBold' }
            ]} ellipsizeMode={'tail'} numberOfLines={1}>
            {value}
          </Text>
        </View>
      </View>
    },
    'contact.name': (value, row, column, navigationFn, setOpenModal) => {
      return <View left>
        <TouchableOpacity onPress={() => {
          if (Platform.OS === 'web') {
            setOpenModal({
              ["Contacts"]: {
                isVisible: true,
                moduleName: "Contacts",
                id: row.contact_id,
                viewOnly: true,
                afterSaveEvent: 'reloadListing',
              }
            })
          } else {
            navigationFn('New', {
              moduleName: "Contacts",
              id: row.contact_id,
              viewOnly: true,
            });
          }
        }}>
          <Text
            monoTableBody={false}
            monoTableHeading={false}
            style={[
              column.customCellStyle,
              { maxWidth: '100%' },
              { color: '#007bff' },
              { fontFamily: 'SourceSansProSemiBold' }
            ]} ellipsizeMode={'tail'} numberOfLines={1}>
            {value}
          </Text>
        </TouchableOpacity>
      </View>
    },
    'resume_appointment': (value, row, column, navigationFn, setOpenModal) => {
      if (row.status == 'Started') {
        return <View left>
          <TouchableOpacity onPress={() => {
            row.status = 'Started';
            api.update('Appointments', row, row._id).then((res) => {
              DeviceEventEmitter.emit('goToEdit', row);
            })
          }}>
            <Text
              monoTableBody={false}
              monoTableHeading={false}
              style={[
                column.customCellStyle,
                { maxWidth: '100%' },
                { color: '#007bff' },
                { fontFamily: 'SourceSansProSemiBold' }
              ]} ellipsizeMode={'tail'} numberOfLines={1}>
              Resume Visit
            </Text>
          </TouchableOpacity>
        </View>
      }
      if (row.status == 'Scheduled') {
        return <View left>
          <TouchableOpacity onPress={() => {
            row.status = 'Started';
            api.update('Appointments', row, row._id).then((res) => {
              DeviceEventEmitter.emit('goToEdit', row);
            })
          }}>
            <Text
              monoTableBody={false}
              monoTableHeading={false}
              style={[
                column.customCellStyle,
                { maxWidth: '100%' },
                { color: '#007bff' },
                { fontFamily: 'SourceSansProSemiBold' }
              ]} ellipsizeMode={'tail'} numberOfLines={1}>
              Start Visit
            </Text>
          </TouchableOpacity>
        </View>
      }

      return <View left>
        <TouchableOpacity onPress={() => {
          DeviceEventEmitter.emit('goToEdit', row);
        }}>
          <Text
            monoTableBody={false}
            monoTableHeading={false}
            style={[
              column.customCellStyle,
              { maxWidth: '100%' },
              { color: '#007bff' },
              { fontFamily: 'SourceSansProSemiBold' }
            ]} ellipsizeMode={'tail'} numberOfLines={1}>
            {row.status == "Completed" ? "Edit" :  "Reschedule"}
          </Text>
        </TouchableOpacity>
      </View>
    }
  },
  customActions: (moduleName, navigationFn, route, row, setOpenModal, openCustomModal) => {
    let options = [];

    if (row.status == 'Scheduled') {
      options = [
        ...options,
        {
          label: 'Start Visit',
          key: 'start_visit',
          fn: (props) => {
            const row = props.row;
            row.status = 'Started';
            api.update(moduleName, row, row._id).then((res) => {
              DeviceEventEmitter.emit('goToEdit', row);
            })
          }
        },
        {
          label: 'Mark as Cancelled',
          key: 'mark_as_cancelled',
          fn: (props) => {
            const row = props.row;
            row.status = 'Cancelled';
            api.update(moduleName, row, row._id).then((res) => {
              DeviceEventEmitter.emit('reloadListing');
            })
          }
        }
      ]
    }

    if (row.status == 'Started') {
      options = [
        ...options,
        {
          label: 'Resume Appointment',
          key: 'resume_appointment',
          fn: (props) => {
            const row = props.row;
            row.status = 'Started';
            api.update(moduleName, row, row._id).then((res) => {
              DeviceEventEmitter.emit('goToEdit', row);
            })
          }
        },
        {
          label: 'Mark as Cancelled',
          key: 'mark_as_cancelled',
          fn: (props) => {
            const row = props.row;
            row.status = 'Cancelled';
            api.update(moduleName, row, row._id).then((res) => {
              DeviceEventEmitter.emit('reloadListing');
            })
          }
        }
      ]
    }

    options.push({
      label: 'Generate Invoice',
      key: 'generate_invoice',
      fn: (props) => {
        if (Platform.OS === 'web') {
          setOpenModal({
            ["Invoices"]: {
              isVisible: true,
              moduleName: "Invoices",
              afterSaveEvent: 'reloadListing',
              is_default_values: true,
              contact_id: row.contact_id,
            }
          })
        } else {
          navigationFn('New', {
            moduleName: "Invoices",
            goBackAfterSave: true,
            is_default_values: true,
            contact_id: row.contact_id,
          });
        }
      }
    })

    options.push({
      label: 'Receive Payment',
      key: 'receive_payment',
      fn: (props) => {
        if (Platform.OS === 'web') {
          setOpenModal({
            ["PaymentReceived"]: {
              isVisible: true,
              moduleName: "PaymentReceived",
              afterSaveEvent: 'reloadListing',
              is_default_values: true,
              contact_id: row.contact_id,
            }
          })
        } else {
          navigationFn('New', {
            moduleName: "PaymentReceived",
            goBackAfterSave: true,
            is_default_values: true,
            contact_id: row.contact_id,
          });
        }
      }
    })

    options = [
      ...options.map((item) => {
        return { ...item, ignore_permission: true }
      }),
    ]

    return options;
  },
  defaultView: "Today's Queue",
  defaultFilters: [
    {
      "field": "date",
      "operators": [
        {
          "label": "equals",
          "value": "eq"
        },
        {
          "label": "not equals",
          "value": "ne"
        },
        {
          "label": "greater than",
          "value": "gt"
        },
        {
          "label": "less than",
          "value": "lt"
        },
        {
          "label": "greater than or equals",
          "value": "gte"
        },
        {
          "label": "less than or equals",
          "value": "lte"
        },
        {
          "label": "is null",
          "value": "isNull"
        },
        {
          "label": "is not null",
          "value": "isNotNull"
        },
        {
          "label": "date range",
          "value": "dateRange"
        },
        {
          "label": "today",
          "value": "today"
        },
        {
          "label": "yesterday",
          "value": "yesterday"
        },
        {
          "label": "tomorrow",
          "value": "tomorrow"
        },
        {
          "label": "next 7 days",
          "value": "next7Days"
        },
        {
          "label": "last 7 days",
          "value": "last7Days"
        },
        {
          "label": "this week",
          "value": "thisWeek"
        },
        {
          "label": "next week",
          "value": "nextWeek"
        },
        {
          "label": "last month",
          "value": "lastMonth"
        },
        {
          "label": "this month",
          "value": "thisMonth"
        },
        {
          "label": "next month",
          "value": "nextMonth"
        },
        {
          "label": "today & earlier",
          "value": "todayAndEarlier"
        },
        {
          "label": "last quarter",
          "value": "lastQuarter"
        },
        {
          "label": "this quarter",
          "value": "thisQuarter"
        },
        {
          "label": "next quarter",
          "value": "nextQuarter"
        },
        {
          "label": "overdue",
          "value": "overdue"
        },
        {
          "label": "later than today",
          "value": "laterThanToday"
        },
        {
          "label": "last",
          "value": "last"
        },
        {
          "label": "next",
          "value": "next"
        },
        {
          "label": "next year",
          "value": "nextYear"
        },
        {
          "label": "this year",
          "value": "thisYear"
        },
        {
          "label": "last year",
          "value": "lastYear"
        },
        {
          "label": "last week",
          "value": "lastWeek"
        },
        {
          "label": "exact date",
          "value": "exactDate"
        },
        {
          "label": "before date",
          "value": "beforeDate"
        },
        {
          "label": "after date",
          "value": "afterDate"
        },
        {
          "label": "no date",
          "value": "noDate"
        }
      ],
      "type": "date",
      "operator": "today"
    }
  ],
  ExtraSaveComponent: ExtraSaveComponent,
  CustomViewHeader: CustomViewHeader,
  CustomEditHeader: CustomEditHeader,
}